<template>
  <div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for=""> Fournisseur </label>
        <select
          class="form-select"
          v-model="payment.supplier_reference"
          @change="getSupplierPurchasesUnpaid(payment.supplier_reference)"
        >
          <option value="" default></option>
          <option
            v-for="(supplier, index) in suppliers"
            :key="index++"
            :value="supplier.reference"
          >
            <div class="row justify-content-between">
              <span class="col-auto">
                {{ supplier.fullName }}
              </span>
              <span class="col-auto">
                {{ supplier.society }}
              </span>
            </div>
          </option>
        </select>
      </div>

      <div class="col-7">
        <label for=""> Factures impayées </label>
        <select class="form-select" v-model="payment.purchase_reference">
          <option value="" default></option>
          <option
            v-for="(purchase, index) in purchases"
            :key="index++"
            :value="purchase.reference"
            :style="{ color: 'red' }"
          >
            <div class="row justify-content-between">
              Nº {{ purchase.reference }}, Facturait le
              <span class="col-auto text-success">
                {{ purchase.date }}
              </span>
              Payer
              <span :style="{ color: 'green' }" class="col-auto text-success">
                {{ purchase.amountPaid }}
              </span>
              à partir de
              <span class="col-auto">
                {{ purchase.totalPriceTTC }}
              </span>
              reste
              <span class="col-auto text-danger">
                {{ purchase.amountUnpaid }}
              </span>
            </div>
          </option>
        </select>
      </div>
      <div class="col-2">
        <label for="">Montant</label>
        <input type="text" v-model="payment.amount" class="form-control" />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-3">
        <label for="">Date</label>
        <input type="date" v-model="payment.date" class="form-control" />
      </div>
      <div class="col-3">
        <label for="">Mode de paiement</label>
        <select v-model="payment.paymentMethod" class="form-select">
          <option value="Espèce">Espèce</option>
          <option value="Virement bancaire">Virement bancaire</option>
          <option value="Chèque">Chèque</option>
          <option value="Effet">Effet</option>
        </select>
      </div>

      <div class="col-3">
        <label for="">Numéro de chèque</label>
        <input type="text" v-model="payment.checkNumber" class="form-control" />
      </div>

      <div class="col-3">
        <label for="">Numéro de virement bancaire</label>
        <input
          type="text"
          v-model="payment.bankTransferNumber"
          class="form-control"
        />
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <label for="">Remarques</label>
        <textarea class="form-control" cols="30" rows="3"></textarea>
      </div>
    </div>
    <br />
    <div class="row mt-3 justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button class="btn btn-success" @click="save(payment)">
          <i class="bi bi-check-square-fill"></i> Enregistrer
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      payment: {
        date: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    }; 
  },
  computed: {
    ...mapGetters("supplier", {
      suppliers: "getAll",
    }),
    ...mapGetters("purchase", { purchases: "getPurchases" }),
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("payment/storePaymentSupplier", data);
    },
    async getSupplierPurchasesUnpaid(supplier_reference) {
      this.$store.dispatch("purchase/getSupplierPurchasesUnpaid", supplier_reference);
    },
  },
  beforeMount() {
    this.$store.dispatch("supplier/getAll");
  },
};
</script>
